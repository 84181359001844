<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
        :src="require('@/page/namvaytien/components/img/logo.png')"
        :alt="logo"
        class="logoImg"
      /> -->
        <span class="title">ROYAL START TECHNOLOGY SERVICES COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': isActive('/') }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Về</span>
          </router-link>
        </li>
        <!-- <li :class="{ 'active-link': $route.path === '/introduce' }" class="Li2">
        <router-link to="/introduce" class="nav-link2">
          <span class="routeText2">การแนะนำสินเชื่อ</span>
        </router-link>
      </li> -->
        <li :class="{ 'active-link': $route.path === '/about' }" class="Li2">
          <router-link to="/about" class="nav-link2">
            <span class="routeText2">Khoản vay</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/question' }" class="Li3">
          <router-link to="/question" class="nav-link3">
            <span class="routeText3">Câu hỏi thường gặp</span>
          </router-link>
        </li>
        <li class="Li4">
          <a href="https://fly.royalstarttscl.com/r0o8Yv9y/jZ1x3Cpa.html" class="nav-link3" target="_blank">
            <span class="routeText4">Acuerdo de privacidad</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "@/page/namvaytien/components/img/Rectangle.png",
      link1Color: "white",
    };
  },
  methods: {

    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: "";
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* height: 80px; */
  padding: 0 15px;
  background-color: #000;
  /* box-shadow: 0 2px #682F1C; */
}


.logo {
  font-weight: bold;
  color: black;
  display: flex;
  /* 让 Logo 内容成为一个 flex 容器，使图片和文字水平排列 */
  align-items: center;
  /* 垂直居中对齐 */
  padding-left: 10px;
}

.logoImg {
  width: 50px;
  height: 50px;
}

.title {
  width: 430px;
  height: 24px;
  font-size: 16px;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 23px;
  padding-left: 16px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 60px;
  opacity: 0.85;
  /* text-transform: uppercase; */
}



.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;

  margin: 0 auto;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  /* padding: 6px; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 72px;
  /* padding-left: 4px;
  padding-right: 4px; */
  /* border: 1px solid red; */
}

.Li1 {
  width: 80px;
}

.Li2 {
  width: 120px;
}

.Li3 {
  width: 180px;
}

.Li4 {
  width: 230px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.Li5 {
  width: 210px;
  /* 这个如果给margin-right底部可能会出现边距的问题 */
}

.nav-links li {
  background-size: 100% 30%;
}

.active-link {
  /* background: url("@/page/hipopresamo/components/img/activeBg.png"); */
  border-top: 3px solid #FEC745;
  /* background-color: #EBF7FF; */
  background-size: 100% 100%;
  /* 背景图片充满整个容器 */
  background-repeat: no-repeat;
}

.active-link .routeText {
  color: #FEC745;
}

.active-link span {
  color: #FEC745;
  font-weight: 600;
}

.title {
  /* border: 1px solid red; */
  width: 500px;
  /* padding-left: 100px; */
}

.secondBox {
  padding: 0 100px;
}
</style>